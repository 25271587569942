$color-brand:   #12477A;
$color-brand--oslo-gray:   #868e96;
$color-orange:  #F26522;
$primary:       #0A58CA;

$success_pastel: #1dc9b7;
$info:           #087990; //$cyan-700;
$warning:       #ffc241;
$danger:        #dc3545; //#fd3995;
$light:         #fff;
$dark:          #505050;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
) !default;

//$sidebar-width: 16.875rem;
//$sidebar-toggler-active-border-color: $primary;
//$sidebar-toggler-active-bg: lighten(desaturate($primary, 0.02), 10.00);
//$logo-height: 6.4375rem; // 103px
//$header-height: 66px;
//$profile-dropdown-header-bg--gray: #8e8e8e;
//$profile-dropdown-header-bg--secondary: #3e93d6;

//$enable-gradients: true;
$table-dark-bg:               #c1c1c1; /*$color-brand;*/
$table-dark-border-color:     lighten($table-dark-bg, 7.5%);
$row-toggler-width:           1.8rem;
$table-cell-padding:          .6rem !default;
$table-cell-padding-sm:       .3rem !default;
$table-cell-padding-lg:       .4rem !default;
$table-border-color:          #e9e9e9;
$table-border-nested-color:   #e9e9e9;
//$table-accent-bg:             rgba($color-brand, .05);
$table-dark-hover-color:      #212529;
$table-dark-hover-bg:         #fdfdfd;
//$table-striped-order:         rgba(0, 68, 124, 0.03);
$table-striped-bg:            rgba($color-brand, .05);

$modal-header-padding-y:      .5rem;
$modal-header-padding-x:      1rem;

$link-decoration:             none !important;
$link-hover-decoration:       underline !important;

$breadcrumb-padding-x :       0;
$breadcrumb-padding-y :       0;
$breadcrumb-bg:               transparent;

$badge-font-size:             .75rem;

$box-shadow:                  0 0 13px 0 rgba(74,53,107,.08);
$card-border-color:           rgba(#000, .08);
$input-disabled-bg:           #f3f6fa;
