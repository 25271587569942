/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
//@import 'bootstrap/scss/bootstrap';
@import "@ng-select/ng-select/themes/default.theme.css";

@import 'bootstrap';

@import 'variables';
@import 'bg-color';
@import 'page';
@import 'file-uploader';
@import "bg-color";
@import "utils";
@import "page";
@import "card";
@import "pagination";
@import "dropdown";
//@import "sorting";
@import "loaders";
@import "modals";
@import "panel-toolbar";
@import "mass-action";

@import "table";
@import "ng-select";
@import "row-toggler";

/* Importing Bootstrap SCSS file. */
//@import 'bootstrap/scss/bootstrap';

app-inline-loader {
  display: inline-block;
}

[role="button"] {
  display: inline-block;
}
