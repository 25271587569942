.panel-toolbar  {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .btn-panel {
        margin-left: .3rem;
        padding: 0;
        width: 1rem;
        height: 1rem;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 50%;
        opacity: .8;
        color: $success_pastel;
    }
}

.collapsed{
    .card-hdr {
        border-radius: $border-radius;
    }
    .card-body {
        padding: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
    }
}
