body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #3c4b64;
  background-color: #ebedef;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.background {
  background: url(/assets/img/svg/pattern-1.svg) no-repeat center bottom fixed;
  background-size: cover;
}

.c-sidebar-brand {
  text-align: center;
  background-color: $color-brand;
  height: 100px;
  display: flex;
  overflow: hidden;
  transition: height linear 0.3s;
  align-items: center;
  justify-content: center;
}

.app:not(.legacy-theme):not(.dark-theme) {
  .sidebar {
    &.sidebar-show {
      border: 0;
      box-shadow: 0 3px 4px 0 rgba(60, 75, 100, 0.14), 0 3px 3px -2px rgba(60, 75, 100, 0.12), 0 1px 8px 0 rgba(60, 75, 100, 0.2);
    }
  }
}

html:not([dir=rtl]) {
  .sidebar-unfoldable {
    &:not(:hover) {
      @media (min-width: 992px) {
        margin-left: -56px;
        .c-sidebar-brand {
          height: 56px;
        }
        .nav-child {
          height: 0;
        }
        .sidebar-nav-dropdown-toggle {
          &.toggled {
            ~ .nav-child {
              height: 0;
            }
          }
        }
      }
    }

    .sidebar-minimizer::before {
      @media (min-width: 992px) {
        transform: rotate(-180deg);
      }
    }
  }

  .sidebar {
    margin-left: -256px;

    &.sidebar-lg-show,
    &.sidebar-show {
      @media (min-width: 992px) {
        margin-left: 0;
      }

      &.sidebar-fixed {
        ~ .wrapper {
          @media (min-width: 992px) {
            margin-left: 256px;
          }
        }

        &.sidebar-minimized,
        &.sidebar-unfoldable {
          ~ .wrapper {
            @media (min-width: 992px) {
              margin-left: 56px;
            }
          }
        }

        @media (min-width: 992px) {
          border: 0;
          box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14), 0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);
        }
      }
    }

    &.sidebar-show {
      margin-left: 0;
    }

    &.sidebar-fixed {
      @media (min-width: 992px) {
        left: 0;
      }
    }
  }

  .sidebar-nav-dropdown-toggle::after {
    margin-left: auto;
  }

  .sidebar-nav-icon:first-child {
    margin-left: -1rem;
  }
}

.sidebar {
  display: flex;
  flex: 0 0 256px;
  flex-direction: column;
  order: -1;
  width: 256px;
  padding: 0;
  box-shadow: none;
  color: #fff;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s, margin-right 0.3s, height 0.3s, width 0.3s, z-index 0s ease 0.3s;
  @media (max-width: 991.98px) {
    --is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1031;
  }

  &.sidebar-fixed {
    @media (min-width: 992px) {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 1030;
    }
  }

  .sidebar-unfoldable {
    transition: transform 0.3s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0s;
  }

  .sidebar-nav-link,
  .sidebar-nav-dropdown-toggle {
    color: rgba(255, 255, 255, 0.8);
    background: 0 0;
  }
}


.sidebar-unfoldable {
  &:not(:hover) {
    z-index: 1031;
    flex: 0 0 56px;

    .sidebar-nav-link,
    .sidebar-nav-dropdown-toggle,
    .sidebar-nav-dropdown-toggle {
      @media (min-width: 992px) {
        overflow: hidden;
        white-space: nowrap;
        border-left: 0;
      }
    }

    &.sidebar-fixed {
      @media (min-width: 992px) {
        z-index: 1031;
        width: 56px;
      }
    }
  }

  @media (min-width: 992px) {
    z-index: 1031 !important;
  }


  .sidebar-minimizer {
    @media (min-width: 992px) {
      position: fixed;
      bottom: 0;
      width: inherit;
    }
  }
}

.sidebar-nav {
  width: inherit;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;

  &.nav-child .sidebar-nav-link:not(.router-link-active):not(:hover) {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.nav-child {
  height: 0;
  overflow-y: hidden;
  transition: transform 0.3s, height 0.3s, z-index 0s ease 0s;
}

.sidebar-nav-dropdown {
  position: relative;
  transition: background 0.3s ease-in-out;
}

.sidebar-nav-link.router-link-active,
.active.sidebar-nav-dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);

  &:before {
    content: ' ';
    position: absolute;
    right: 0;
    border: 0.5rem solid transparent;
    border-right: 0.5rem solid #ebedef;
  }
}

.sidebar-nav-link,
.sidebar-nav-dropdown-toggle {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.8445rem 1rem;
  text-decoration: none;
  white-space: nowrap;
  transition: background 0.3s, color 0.3s;
  color: rgba(255, 255, 255, 0.8);
  background: 0 0;

  &:hover {
    @media (hover: hover), not all {
      color: #fff;
      //background-color: #343a40;
      background-color: rgba(0, 0, 0, .25);
      text-decoration: none;
    }
  }

  &:not(.active):not(.router-link-active) {
    .sidebar-nav-icon {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.sidebar-nav-dropdown-toggle {
  cursor: pointer;
  position: relative;
  transition: background 0.3s ease-in-out;

  &:after {
    display: block;
    flex: 0 8px;
    height: 8px;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s;

    background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0ncmdiYSgyNTUsIDI1NSwgMjU1LCAwLjUpJyBkPSdNOS4xNDggMi4zNTJsLTQuMTQ4IDQuMTQ4IDQuMTQ4IDQuMTQ4cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MmwtMS4yOTcgMS4yOTdxLTAuMTQ4IDAuMTQ4LTAuMzUyIDAuMTQ4dC0wLjM1Mi0wLjE0OGwtNS43OTctNS43OTdxLTAuMTQ4LTAuMTQ4LTAuMTQ4LTAuMzUydDAuMTQ4LTAuMzUybDUuNzk3LTUuNzk3cTAuMTQ4LTAuMTQ4IDAuMzUyLTAuMTQ4dDAuMzUyIDAuMTQ4bDEuMjk3IDEuMjk3cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MnonLz48L3N2Zz4=);
    transform: rotate(270deg);
  }

  &.toggled {
    &:after {
      transform: rotate(90deg);
    }

    ~ .nav-child {
      height: 100%;
    }
  }
}

//.sidebar-nav-title {
//  padding: 0.75rem 1rem;
//  margin-top: 1rem;
//  font-size: 80%;
//  font-weight: 700;
//  text-transform: uppercase;
//  transition: 0.3s;
//  color: rgba(255, 255, 255, 0.6);
//}

.sidebar-nav-icon {
  flex: 0 0 56px;
  //height: 1.09375rem;
  //font-size: 1.09375rem;
  text-align: center;
  transition: 0.3s;
  fill: currentColor;
}

.sidebar-minimizer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  border: 0;
  background-color: rgba(0, 0, 21, 0.2);

  &::before {
    display: block;
    width: 50px;
    height: 50px;
    content: "";
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12.5px;
    transition: 0.3s;
    background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nIzhhOTNhMicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
  }

  @media(max-width: 991.98px) {
    display: none
  }

  &:focus,
  &.focus {
    outline: 0
  }

  @media(min-width: 992px) {
    //z-index: 1031;
    //-ms-flex: 0 0 56px;
    //flex: 0 0 56px;
    &.sidebar-fixed {
      z-index: 1031;
      width: 56px
    }
  }
}

*[dir=rtl] .sidebar-minimizer::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.sidebar-brand-full {
  //display: none
  transition: all linear 0.3s;
}

.sidebar-brand-minimized {
  //display: none;
  transform: scaleX(0);
  width: 0;
  transition: all linear 0.3s;
}

@media(min-width: 992px) {
  .sidebar-minimized {
    .sidebar-brand-full {
      transform: scaleX(0);
      width: 0;
      //display: none;
    }

    .sidebar-brand-minimized {
      width: 40px;
      transform: scaleX(1);
      //display: inline-block;
    }
  }

  .sidebar-unfoldable {
    .sidebar-nav:not(.nav-child) {
      padding-bottom: 50px;
      overflow: visible;
    }

    &:not(:hover) {
      .sidebar-brand-full {
        transform: scaleX(0);
        width: 0;
        //display: none;
      }

      .sidebar-brand-minimized {
        width: 40px;
        transform: scaleX(1);
        //display: inline-block;
      }
    }

    .sidebar-fixed {
      z-index: 1031;
      width: 56px;
    }
  }
}
@media (min-width: 768px) {
  .main > .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.profile-panel {
  width: 20rem;
  right: 0;
  top: 56px;
  bottom: 0;
  position: absolute;
  z-index: 5;
  transition: margin-right 0.3s;
}

.profile-panel:not(.profile-panel-show) {
  margin-right: -20rem;
}
