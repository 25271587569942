.main-pagination {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    .ngx-pagination {
        padding: 0;
        margin: 2px 0;
        white-space: nowrap;
        li {
            border-radius: $border-radius;
        }
        .current {
            background-color: $color-brand;
            color: #fdfdfd;
        }
        a {
            color: $color-brand;
        }
        .pagination-previous a::before,
        .pagination-previous.disabled::before{
            content: "<";
        }
        .pagination-next a::after,
        .pagination-next.disabled::after{
            content: ">";
        }
    }
}
