@import "src/styles/variables";

.mass-btn-wrapper {
    display: flex;
    color: #fff;
    text-align: center;
    @media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        .inner {
            width: 100%;

            & > button {
                width: 25%;
                background-color: $color-brand;
                border-color: $color-brand;
                border-right: 1px solid darken($color-brand, 10%) !important;
                border-left: 1px solid darken($color-brand, 10%) !important;
            }
        }
    }
}
