.card-hdr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    min-height: 3rem;
    border-bottom: 1px solid rgba(0,0,0,.07);
    border-radius: 4px 4px 0 0;
    -webkit-transition: background-color .4s ease-out;
    transition: background-color .4s ease-out;
    @media only screen and (min-width: map-get($grid-breakpoints, "lg")) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    padding-left: .5rem;
    padding-right: .5rem;
    h1, .h1 {
        font-size: 1.15rem;
    }
    h2, h2 {
        font-size: .875rem;
    }
    h1, h2 {
        -webkit-box-flex: 1;
        flex: 1;
        margin: 0;
        //display: -webkit-box;
        //display: flex;
        //-webkit-box-align: center;
        //align-items: center;
        //line-height: 3rem;
        color: #333;
        position: relative;
        font-weight: 500;
        &:not(:only-child) {
            margin-right: .66667rem;
        }
    }
}

.card-body {
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
    height: 100%;
    opacity: 1;
    &.p-xs-lg-0{
        @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
            padding: 0;
        }
    }
}
.card-footer,
.card-body {
    @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
        & {
            padding: .5rem;
        }
        [class^="col-"], [class*=" col-"] {
            padding-right: .5rem;
            padding-left: .5rem;
        }
        .row {
            margin-right: -.5rem;
            margin-left: -.5rem;
        }
    }
}
