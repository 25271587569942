@import "bg-color";

.table {
  font-size: .8rem;
  margin-bottom: 0;

  &:not(table .second-level) {
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
  }

  tbody + tbody {
    border-top-width: 1px;
  }

  thead th {
    border-bottom-width: 3px;
  }

  .second-level {
    transition: all .3s;

    > td {
      padding: 0 0 0 $row-toggler-width;
    }

    thead, .table-dark:hover {
      //@extend .bg-gradient-brand;
      //@extend .bg-gradient-brand--h;
      background-color: $table-dark-bg;
    }

    & > td, .table-bordered {
      border-width: 0;
    }
  }

  @media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
    th, td {
      padding: .5rem;
    }

    .second-level .table {
      th, td {
        //padding: .3rem;
      }
    }
  }

  &.two-levels {
    &.table-striped {
      tbody tr {
        &:nth-of-type(odd) {
          background-color: transparent;

          &:not(.second-level) {
            background-color: rgba($table-dark-bg, .05);
          }
        }
      }

      .first-level {
        &:nth-of-type(odd) tr:not(.second-level) {
          //background-color: rgba($color-brand, .05);
        }

        //&:nth-of-type(even) {
        //background-color: transparent;
        //background-color: #fff;
        //}
      }

      .second-level {
        tr:nth-of-type(odd) {
          //background-color: transparent !important;
        }

        thead th {
          border-top-color: $table-dark-border-color;
          border-right-color: $table-dark-border-color;
          border-left-color: $table-dark-border-color;
          border-bottom-color: $table-border-color;
          color: #ffffff;
        }
      }
    }
  }

  &.table-hover {
    tbody tr:hover {
      color: $table-dark-hover-color;
      background-color: $table-dark-hover-bg;
      -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
      box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
      z-index: 1;
    }
  }

  &.table-pointer {
    tbody tr {
      &:hover, &.hover {
        cursor: pointer;
      }
    }
  }

  .badge {
    font-size: .75rem;
  }

  .item-row-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    .btn {
      @include button-size(0.15rem, $btn-padding-y-sm, .8rem, .5rem);

      &:not(:first-of-type) {
        margin-left: .3rem;
      }

      margin-top: .2rem;
      margin-bottom: .2rem;
    }
  }

  .filter-row {

    .dropdown-toggle {
      width: 100%;
      max-width: 200px;
      text-align: right;
    }

    .btn {
      font-weight: 400;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }

    .dropdown-item {
      padding-left: .5rem;
      padding-right: .5rem;

      &.active, &:active {
        background-color: transparent;
      }
    }
  }
}

.table-info {
  background-color: #edf2f9;
  text-align: center;
  padding: .6rem;
  position: relative;
  border: $border-width solid $border-color;

  &:after {
    width: 100%;
    height: 1rem;
  }

  &.no-data {
    &:after {
      content: 'Nothing to display';
    }
  }

  &.loading {
    &:after {
      content: 'Loading...';
    }
  }
}
