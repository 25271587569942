@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@media (max-width: 991.98px) {
  .d-md-down-none {
    display: none !important;
  }
}

.fade {
  //transition: opacity 0.15s linear, height 0.1s linear;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.fade:not(.show) {
  //opacity: 0;
  //height: 0;
  //overflow: hidden;
}
.fade.show {
  //height: 100%;
}

.btn-sm-down-block {
  @include media-breakpoint-down(sm, $grid-breakpoints) {
    width: 100%;
    display: block;
  }
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

.text-decoration {
  &-underline {
    text-decoration: underline;
  }
}

app-inline-loader {
  display: inline-block;
}

.form-select + app-inline-loader {
  position: absolute;
  right: 1.75rem;
}

.opacity-50 {
  opacity: .5;
}

$sizes: 55px, 75px, 80px, 85px, 100px, 110px, 120px, 125px, 135px, 150px, 155px, 170px, 250px;

@each $size in $sizes {
  .w-#{$size} {
    width: $size;
    max-width: $size;
    min-width: $size;
  }
}
